<template>
  <div class="container_index">
    <div class="top">
      <div>{{ schoolData.schoolName }}</div>
      <!-- <div style="margin-left: 190px">
        <span style="font-size: 16px">全校人数:</span> {{schoolStudents}}<span
          style="font-size: 16px"
          >人</span
        >
      </div>
      <div style="margin-left: 75px">
        <span style="font-size: 16px">学生卡数:</span> {{schoolDevices}}<span
          style="font-size: 16px"
          >人</span
        >
      </div> -->
      <!-- <div style="margin-left: 75px">
        <span style="font-size: 16px">在线率:</span
        ><span style="color: #5d9cff"> 99</span
        ><span style="font-size: 16px">%</span>
      </div> -->
      <div class="user">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <span style="font-size: 16px">{{ schoolData.schoolManager }}</span>
            <img src="../assets/ico_down.png" style="margin-left: 15px" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="info">
      <div
        style="
          width: 5px;
          height: 40px;
          background: #a2c8fa;
          margin-right: 20px;
        "
      ></div>
      <!-- <img src="../assets/ico_xiaoxi.png" style="margin-right: 10px" />
      <div>消息：</div>
      <img
        src="../assets/but_sos.png"
        style="position: absolute; right: 40px"
      /> -->
      <div class="detailRight" style="position: absolute; right: 40px">
        允许上级单位查看本校学生智慧卡相关信息
        <el-switch
          style="margin-left: 10px; margin-top: 2px"
          v-model="allow"
          active-color="#5D9CFF"
          inactive-color="#a5a5a5"
          @change="switchChange"
        >
        </el-switch>
      </div>
      <div class="details">
        基础信息<img
          src="../assets/ico_you.png"
          style="margin-top: 2px"
        />校园信息
      </div>
    </div>
    <div class="menu">
      <div class="head">
        <img
          :src="
            schoolData.schoolPhotoUrl == ''
              ? require('../assets/school.png')
              : schoolData.schoolPhotoUrl
          "
          style="margin-left: 23px; width: 115px; height: 115px"
        />
      </div>
      <div class="item" @click="$router.push('/index')">
        <img
          src="../assets/ico_shouye.png"
          style="margin-right: 4px"
        />&nbsp;首&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;页
      </div>
      <div
        class="item"
        style="
          background: #f5f6fa;
          color: #5d9cff;
          border-bottom: 1px solid #b9d5fa;
        "
      >
        <div style="display: flex; align-items: center">
          <img
            style="margin-right: 10px"
            src="../assets/ico_jichuguanli_blue.png"
          />基础管理
        </div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/schoolManage')"
      >
        <div style="display: flex; align-items: center">校区管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/gradeManage')"
      >
        <div style="display: flex; align-items: center">年级管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/classManage')"
      >
        <div style="display: flex; align-items: center">班级管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/teacherManage')"
      >
        <div style="display: flex; align-items: center">教师管理</div>
      </div>
      <div
        class="item-white"
        style="
          color: #9ac1fd;
          height: 38px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 14px;
        "
        @click="$router.push('/studentManage')"
      >
        <div style="display: flex; align-items: center">学生管理</div>
      </div>
      <div class="item" @click="$router.push('/teacherApply')">
        <img
          style="margin-right: 10px"
          src="../assets/request_white.png"
        />教师申请
      </div>
      <div class="item" @click="$router.push('/studentApply')">
        <img
          style="margin-right: 10px"
          src="../assets/request_white.png"
        />学生申请
      </div>
      <div class="item" @click="$router.push('/deviceManage')">
        <img
          style="margin-right: 10px"
          src="../assets/devices_white.png"
        />设备管理
      </div>
      <div class="item" @click="$router.push('/signManage')">
        <img
          style="margin-right: 10px"
          src="../assets/signin_white.png"
        />签到管理
      </div>
      <div class="item" @click="$router.push('/leaveManage')">
        <img
          style="margin-right: 10px"
          src="../assets/leave_white.png"
        />请假管理
      </div>
      <div class="item" @click="$router.push('/noticeManage')">
        <img
          style="margin-right: 10px"
          src="../assets/speaker_white.png"
        />学校公告
      </div>
    </div>
    <div class="content">
      <div class="card">
        <div class="inner">
          <span style="display: flex; align-items: center; position: relative"
            ><img
              :src="
                schoolData.schoolPhotoUrl == ''
                  ? require('../assets/school.png')
                  : schoolData.schoolPhotoUrl
              "
              style="
                margin-right: 15px;
                border-radius: 50%;
                height: 85px;
                width: 85px;
              "
            />{{ schoolData.schoolName }}
            <el-upload
              style="position: absolute; right: 0"
              action="https://smartcard.iradar.cn/file/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <span style="float: right; font-size: 12px; color: #5d9cff"
                >修改图片</span
              >
            </el-upload>
          </span>
          <div style="font-size: 18px; position: relative; margin-top: 10px">
            <span>位置信息</span
            ><span
              style="
                position: absolute;
                right: 0px;
                font-size: 12px;
                color: #5d9cff;
              "
              @click="
                placeSet = true;
                complete = false;
                parsePlace();
              "
              v-if="!placeSet && schoolData.schoolAddress"
              >修改</span
            >
            <span
              style="
                position: absolute;
                right: 0px;
                font-size: 12px;
                color: #5d9cff;
              "
              @click="setPlace"
              v-if="placeSet || !schoolData.schoolAddress"
              >保存</span
            >
          </div>
          <div
            style="font-size: 16px; font-weight: 400; margin-top: 24px"
            v-if="!placeSet && schoolData.schoolAddress"
          >
            所在地区：{{
              `${schoolData.schoolAddress.split(",")[0]}-${
                schoolData.schoolAddress.split(",")[1]
              }`
            }}
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              margin-top: 24px;
              display: flex;
            "
            v-else
          >
            所在地区：<v-distpicker
              @selected="selected"
              :province="province"
              :city="city"
              :area="area"
            ></v-distpicker>
          </div>
          <div
            style="font-size: 16px; font-weight: 400; margin-top: 16px"
            v-if="!placeSet && schoolData.schoolAddress"
          >
            详细地址：{{
              `${schoolData.schoolAddress.split(",")[0]}-${
                schoolData.schoolAddress.split(",")[1]
              }-${schoolData.schoolAddress.split(",")[2]}`
            }}
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              margin-top: 24px;
              display: flex;
            "
            v-else
          >
            详细地址：<el-input
              v-model="address"
              style="width: 80%"
              placeholder="请输入详细地址..."
            ></el-input>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="inner">
          <div style="position: relative">
            管理员信息
            <span
              style="
                position: absolute;
                right: 0px;
                bottom: 0px;
                font-size: 12px;
                color: #5d9cff;
              "
              @click="managerSet1 = true"
              v-if="!managerSet1 && !managerSet2"
              >修改</span
            >
            <span
              style="
                position: absolute;
                right: 0px;
                bottom: 0px;
                font-size: 12px;
                color: #5d9cff;
              "
              @click="goNext()"
              v-if="managerSet1"
              >下一步</span
            >
            <span
              style="
                position: absolute;
                right: 0px;
                bottom: 0px;
                font-size: 12px;
                color: #5d9cff;
              "
              @click="saveManager"
              v-if="managerSet2"
              >保存</span
            >
          </div>
          <div style="font-size: 16px; font-weight: 400; margin-top: 24px">
            管理员：{{ schoolData.schoolManager }}
          </div>
          <div
            style="font-size: 16px; font-weight: 400; margin-top: 16px"
            v-if="!managerSet2"
          >
            联系方式：{{ schoolData.schoolManagerPhone }}
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              margin-top: 16px;
              display: flex;
              position: relative;
            "
            v-if="managerSet2"
          >
            联系方式：<el-input
              v-model="phoneNumber"
              style="width: 80%"
              placeholder="请输入手机号"
            ></el-input>
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              margin-top: 16px;
              display: flex;
              position: relative;
            "
            v-if="managerSet1"
          >
            验&nbsp;&nbsp;证&nbsp;&nbsp;码：<el-input
              v-model="captcha"
              style="width: 80%"
              placeholder="请输入验证码"
            ></el-input>
            <el-button
              class="confirm"
              type="primary"
              @click="getCode"
              :disabled="buttonDisable"
              >{{ codeButton }}</el-button
            >
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              margin-top: 16px;
              display: flex;
              position: relative;
            "
            v-if="managerSet2"
          >
            验&nbsp;&nbsp;证&nbsp;&nbsp;码：<el-input
              v-model="captcha"
              style="width: 80%"
              placeholder="请输入验证码"
            ></el-input>
            <el-button
              class="confirm"
              type="primary"
              @click="getCode2"
              :disabled="buttonDisable"
              >{{ codeButton }}</el-button
            >
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              margin-top: 16px;
              display: flex;
              position: relative;
            "
            v-if="managerSet2"
          >
            密&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;码：<el-input
              v-model="password"
              style="width: 80%"
              placeholder="请输入修改后的密码"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="inner">
          <div>基础信息</div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              margin-top: 24px;
              position: relative;
            "
          >
            年级数：{{ gradeNum }}个<span
              style="
                position: absolute;
                right: 0px;
                bottom: 0px;
                font-size: 12px;
                color: #5d9cff;
              "
              @click="$router.push('/gradeManage')"
              >查看</span
            >
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              margin-top: 16px;
              position: relative;
            "
          >
            班级数：{{ classNum }}个<span
              style="
                position: absolute;
                right: 0px;
                bottom: 0px;
                font-size: 12px;
                color: #5d9cff;
              "
              @click="$router.push('/classManage')"
              >查看</span
            >
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              margin-top: 16px;
              position: relative;
            "
          >
            学生数：{{ studentNum }}个<span
              style="
                position: absolute;
                right: 0px;
                bottom: 0px;
                font-size: 12px;
                color: #5d9cff;
              "
              @click="$router.push('/studentManage')"
              >查看</span
            >
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              margin-top: 24px;
              display: flex;
              align-items: center;
            "
          >
            设备数：
            <div
              style="
                width: 165px;
                height: 37px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #979797;
              "
            >
              {{ cardNum }}个
            </div>
            <div
              style="
                width: 165px;
                height: 37px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #979797;
                margin-left: 28px;
              "
            >
              在线率：<span style="color: #5d9cff">{{ onlineRate }}%</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="inner">
          <div>安全围栏</div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              margin-top: 24px;
              position: relative;
              display: flex;
            "
          >
            安全区域：
            <div style="overflow: overlay; max-height: 160px">
              <div
                style="margin-left: 16px"
                v-for="(item, index) in enclosures"
                :key="index"
              >
                <div class="right_items">
                  {{ item.enclosureName
                  }}<span
                    style="
                      position: absolute;
                      right: 50px;
                      bottom: 4px;
                      font-size: 12px;
                      color: #5d9cff;
                      cursor: pointer;
                    "
                    @click="$router.push('/setFence/' + item.enclosureId)"
                    >修改</span
                  >
                  <span
                    style="
                      position: absolute;
                      right: 20px;
                      bottom: 4px;
                      font-size: 12px;
                      color: red;
                      cursor: pointer;
                    "
                    @click="deleteEnclosure(item.enclosureId)"
                    >删除</span
                  >
                </div>
              </div>
              <div
                class="right_items"
                style="color: #5d9cff; margin-left: 16px"
                @click="$router.push('/setFence')"
              >
                添加安全区域
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="inner">
          <div style="position: relative">
            课程时间（春学期）<span
              style="
                position: absolute;
                right: 0px;
                bottom: 0px;
                font-size: 12px;
                color: #5d9cff;
              "
              @click="timeMorning1 = true"
              v-if="!timeMorning1"
              >修改</span
            ><span
              style="
                position: absolute;
                right: 0px;
                bottom: 0px;
                font-size: 12px;
                color: #5d9cff;
              "
              @click="saveTerm1"
              v-if="timeMorning1"
              >保存</span
            >
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              margin-top: 24px;
              position: relative;
              display: flex;
            "
          >
            <span style="width: 64px; text-align: justify"
              >上课时间<span
                style="display: inline-block; width: 100%"
              ></span></span
            >：
            <div style="margin-left: 0px" v-if="!timeMorning1">
              <div class="right_items">{{ dateParser(springTerm) }}</div>
              <div class="right_items">{{ timeParser(springTerm, 1) }}</div>
            </div>
            <div style="margin-left: 0px; width: 430px" v-else>
              <el-date-picker
                v-model="date1"
                @change="setDateSpring"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
              <el-time-picker
                is-range
                arrow-control
                v-model="morning1"
                value-format="HH:mm"
                format="HH:mm"
                @change="setMorningSpring"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
              >
              </el-time-picker>
            </div>
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              position: relative;
              display: flex;
            "
          >
            <span style="width: 64px; text-align: justify"
              >午休时间<span
                style="display: inline-block; width: 100%"
              ></span></span
            >：
            <div style="margin-left: 0px" v-if="!timeMorning1">
              <div class="right_items">{{ timeParser(springTerm, 2) }}</div>
            </div>
            <div style="margin-left: 0px; width: 430px" v-else>
              <el-time-picker
                is-range
                arrow-control
                v-model="timeNoon1"
                @change="setMiddleSpring"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                value-format="HH:mm"
                format="HH:mm"
              >
              </el-time-picker>
            </div>
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              position: relative;
              display: flex;
            "
          >
            <span style="width: 64px; text-align: justify"
              >晚自习<span
                style="display: inline-block; width: 100%"
              ></span></span
            >：
            <div style="margin-left: 0px" v-if="!timeMorning1">
              <div class="right_items">{{ timeParser(springTerm, 3) }}</div>
            </div>
            <div style="margin-left: 0px; width: 430px" v-else>
              <el-time-picker
                is-range
                arrow-control
                v-model="timeNight1"
                @change="setNightSpring"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                value-format="HH:mm"
                format="HH:mm"
              >
              </el-time-picker>
            </div>
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              position: relative;
              display: flex;
            "
          >
            <span style="width: 64px; text-align: justify"
              >重复<span
                style="display: inline-block; width: 100%"
              ></span></span
            >：
            <div style="margin-left: 0px">
              <div class="right_items" v-if="!timeMorning1">
                {{ weekParser(springTerm) }}
              </div>
              <div class="right_items" v-else>
                <el-checkbox-group
                  v-model="checkboxGroup1"
                  @change="setRepeatSpring"
                  size="mini"
                >
                  <el-checkbox-button
                    v-for="date in dates"
                    :label="date"
                    :key="date"
                    >{{ date }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="inner">
          <div style="position: relative">
            课程时间（秋学期）<span
              style="
                position: absolute;
                right: 0px;
                bottom: 0px;
                font-size: 12px;
                color: #5d9cff;
              "
              @click="timeMorning2 = true"
              v-if="!timeMorning2"
              >修改</span
            ><span
              style="
                position: absolute;
                right: 0px;
                bottom: 0px;
                font-size: 12px;
                color: #5d9cff;
              "
              @click="saveTerm2"
              v-if="timeMorning2"
              >保存</span
            >
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              margin-top: 24px;
              position: relative;
              display: flex;
            "
          >
            <span style="width: 64px; text-align: justify"
              >上课时间<span
                style="display: inline-block; width: 100%"
              ></span></span
            >：
            <div style="margin-left: 0px" v-if="!timeMorning2">
              <div class="right_items">{{ dateParser(fallTerm) }}</div>
              <div class="right_items">{{ timeParser(fallTerm, 1) }}</div>
            </div>
            <div style="margin-left: 0px; width: 430px" v-else>
              <el-date-picker
                v-model="date2"
                @change="setDateFall"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
              <el-time-picker
                is-range
                arrow-control
                v-model="morning2"
                value-format="HH:mm"
                format="HH:mm"
                @change="setMorningFall"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
              >
              </el-time-picker>
            </div>
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              position: relative;
              display: flex;
            "
          >
            <span style="width: 64px; text-align: justify"
              >午休时间<span
                style="display: inline-block; width: 100%"
              ></span></span
            >：
            <div style="margin-left: 0px" v-if="!timeMorning2">
              <div class="right_items">{{ timeParser(fallTerm, 2) }}</div>
            </div>
            <div style="margin-left: 0px; width: 430px" v-else>
              <el-time-picker
                is-range
                arrow-control
                v-model="timeNoon2"
                @change="setMiddleFall"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                value-format="HH:mm"
                format="HH:mm"
              >
              </el-time-picker>
            </div>
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              position: relative;
              display: flex;
            "
          >
            <span style="width: 64px; text-align: justify"
              >晚自习<span
                style="display: inline-block; width: 100%"
              ></span></span
            >：
            <div style="margin-left: 0px" v-if="!timeMorning2">
              <div class="right_items">{{ timeParser(fallTerm, 3) }}</div>
            </div>
            <div style="margin-left: 0px; width: 430px" v-else>
              <el-time-picker
                is-range
                arrow-control
                v-model="timeNight2"
                @change="setNightFall"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                value-format="HH:mm"
                format="HH:mm"
              >
              </el-time-picker>
            </div>
          </div>
          <div
            style="
              font-size: 16px;
              font-weight: 400;
              position: relative;
              display: flex;
            "
          >
            <span style="width: 64px; text-align: justify"
              >重复<span
                style="display: inline-block; width: 100%"
              ></span></span
            >：
            <div style="margin-left: 0px">
              <div class="right_items" v-if="!timeMorning2">
                {{ weekParser(fallTerm) }}
              </div>
              <div class="right_items" v-else>
                <el-checkbox-group
                  v-model="checkboxGroup2"
                  @change="setRepeatFall"
                  size="mini"
                >
                  <el-checkbox-button
                    v-for="date in dates"
                    :label="date"
                    :key="date"
                    >{{ date }}</el-checkbox-button
                  >
                </el-checkbox-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="showErrorAlert" width="30%">
      <span>{{ errorMessage }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showErrorAlert = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "basicManage",
  components: {},
  data() {
    return {
      schoolDevices: localStorage.getItem("schoolDevices"),
      schoolStudents: localStorage.getItem("schoolStudents"),
      buttonDisable: false,
      codeButton: "获取验证码",
      captcha: "",
      phoneNumber: "",
      password: "",
      mapType: "class",
      allow: false,
      placeSet: false,
      errorMessage: "",
      showErrorAlert: false,
      address: "",
      managerSet1: false,
      managerSet2: false,
      schoolHead: require("../assets/school.png"),
      timeMorning1: false,
      timeMorning2: false,
      checkboxGroup1: [],
      checkboxGroup2: [],
      dates: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      // timeNoon1: [new Date(2016, 9, 10, 0, 0), new Date(2016, 9, 10, 23, 59)],
      // timeNoon2: [new Date(2016, 9, 10, 0, 0), new Date(2016, 9, 10, 23, 59)],
      // timeNight1: [new Date(2016, 9, 10, 0, 0), new Date(2016, 9, 10, 23, 59)],
      // timeNight2: [new Date(2016, 9, 10, 0, 0), new Date(2016, 9, 10, 23, 59)],
      // morning1: [new Date(2016, 9, 10, 0, 0), new Date(2016, 9, 11, 23, 59)],
      // morning2: [new Date(2016, 9, 10, 0, 0), new Date(2016, 9, 10, 23, 59)],
      timeNoon1: "",
      timeNoon2: "",
      timeNight1: "",
      timeNight2: "",
      morning1: "",
      morning2: "",
      date1: [],
      date2: [],
      prev: "",
      schoolData: {},
      gradeNum: 0,
      classNum: 0,
      studentNum: 0,
      cardNum: 0,
      complete: false,
      springTerm: {},
      fallTerm: {},
      imageUrl: "",
      onlineRate: 0,
      interval: null,
      enclosures: [],
      areaCode: "",
      provinceCode: "",
      cityCode: "",
      province: "",
      city: "",
      area: "",
    };
  },
  mounted() {
    this.schoolData = JSON.parse(localStorage.getItem("schoolData"));
    this.allow = this.schoolData.allowParentLook == 0 ? false : true;
    this.$http
      .get(
        `${this.$baseUrl}/class/group/findGradesBySchoolId?schoolId=${this.schoolData.schoolId}`
      )
      .then((response) => {
        if (response.data.code != 200) {
          this.errorMessage = response.data.message;
          this.showErrorAlert = true;
          return;
        }
        this.gradeNum = response.data.result.total;
      });
    this.$http
      .get(
        `${this.$baseUrl}/classes/findClasses?schoolId=${this.schoolData.schoolId}`
      )
      .then((response) => {
        if (response.data.code != 200) {
          this.errorMessage = response.data.message;
          this.showErrorAlert = true;
          return;
        }
        this.classNum = response.data.result.total;
      });
    this.$http
      .get(
        `${this.$baseUrl}/students/info/findList?schoolId=${this.schoolData.schoolId}`
      )
      .then((response) => {
        if (response.data.code != 200) {
          this.errorMessage = response.data.message;
          this.showErrorAlert = true;
          return;
        }
        this.studentNum = response.data.result.total;
      });
    // this.$http
    //   .get(
    //     `${this.$baseUrl}/card/info/getList?schoolId=${this.schoolData.schoolId}`
    //   )
    //   .then((response) => {
    //     if (response.data.code != 200) {
    //       this.errorMessage = response.data.message;
    //       this.showErrorAlert = true;
    //       return;
    //     }
    //     this.cardNum = response.data.result.total;
    //   });
    this.$http
      .get(
        `${this.$baseUrl}/school/time/config/findList?page=1&size=10&schoolId=${this.schoolData.schoolId}`
      )
      .then((response) => {
        if (response.data.code != 200) {
          this.errorMessage = response.data.message;
          this.showErrorAlert = true;
          return;
        }
        this.springTerm = response.data.result.records.find(
          (element) => element.termType == 1
        );
        this.fallTerm = response.data.result.records.find(
          (element) => element.termType == 0
        );
      });
    this.$http
      .post(`${this.$baseUrl}/card/info/queryDeviceOnlineCurrent`, {
        schoolId: this.schoolData.schoolId,
      })
      .then((response) => {
        this.cardNum = response.data.result.total_nums;
        this.onlineRate = response.data.result.online_nums
          ? (
              (response.data.result.online_nums /
                response.data.result.total_nums) *
              100
            ).toFixed(2)
          : "0";
      });
    this.$http
      .get(
        `${this.$baseUrl}/enclosure/findListBySchoolId?schoolId=${this.schoolData.schoolId}&enclosureType=1`
      )
      .then((response) => {
        if (response.data.code != 200) {
          this.errorMessage = response.data.message;
          this.showErrorAlert = true;
          return;
        }
        this.enclosures = response.data.result;
      });
  },
  methods: {
    parsePlace() {
      setTimeout(() => {
        this.province = this.schoolData.schoolAddress.split(",")[0];
        this.city = this.schoolData.schoolAddress.split(",")[1];
        this.area = this.schoolData.schoolAddress.split(",")[2].split(" ")[0];
        this.complete = true;
        this.address = this.schoolData.schoolAddress.split(",")[2].split(" ")[1] == undefined ? "" : this.schoolData.schoolAddress.split(",")[2].split(" ")[1];
      }, 0);
    },
    deleteEnclosure(id) {
      this.$http
        .delete(`${this.$baseUrl}/enclosure/delete/${id}`)
        .then((response) => {
          if (response.data.code != 200) {
            this.errorMessage = response.data.message;
            this.showErrorAlert = true;
            return;
          }
          this.$http
            .get(
              `${this.$baseUrl}/enclosure/findListBySchoolId?schoolId=${this.schoolData.schoolId}&enclosureType=1`
            )
            .then((response) => {
              if (response.data.code != 200) {
                this.errorMessage = response.data.message;
                this.showErrorAlert = true;
                return;
              }
              this.enclosures = response.data.result;
            });
          // this.complete = true;
        });
    },
    handleAvatarSuccess(res, file) {
      this.schoolHead = res.result.url;
      this.schoolData.schoolPhotoUrl = this.schoolHead;
      this.$http
        .put(`${this.$baseUrl}/school/info/update`, this.schoolData)
        .then((response) => {
          if (response.data.code != 200) {
            this.errorMessage = response.data.message;
            this.showErrorAlert = true;
            return;
          }
          this.$http
            .get(
              `${this.$baseUrl}/school/info/findById/${this.schoolData.schoolId}`
            )
            .then((response) => {
              if (response.data.code != 200) {
                this.errorMessage = response.data.message;
                this.showErrorAlert = true;
                return;
              }
              localStorage.setItem(
                "schoolData",
                JSON.stringify(response.data.result)
              );
              this.schoolData = response.data.result;
              // this.complete = true;
            });
        });
      console.log(file);
    },
    uploadImg() {
      console.log("uploadImg");
    },
    switchChange(val) {
      console.log(val);
      this.schoolData.allowParentLook = val == true ? 1 : 0;
      this.$http
        .put(`${this.$baseUrl}/school/info/update`, this.schoolData)
        .then((response) => {
          if (response.data.code != 200) {
            this.errorMessage = response.data.message;
            this.showErrorAlert = true;
            return;
          }
          this.$http
            .get(
              `${this.$baseUrl}/school/info/findById/${this.schoolData.schoolId}`
            )
            .then((response) => {
              if (response.data.code != 200) {
                this.errorMessage = response.data.message;
                this.showErrorAlert = true;
                return;
              }
              localStorage.setItem(
                "schoolData",
                JSON.stringify(response.data.result)
              );
              this.schoolData = response.data.result;
              // this.complete = true;
            });
        });
    },
    saveManager() {
      if (this.password.length < 1) {
        this.errorMessage = "请设置密码";
        this.showErrorAlert = true;
        return;
      }
      this.$http
        .get(
          `${this.$baseUrl}/sms/check?phone=${this.phoneNumber}&code=${this.captcha}`
        )
        .then((response) => {
          if (
            response.data.code != 200 ||
            response.data.result != "验证码校验成功"
          ) {
            this.errorMessage = response.data.result;
            this.showErrorAlert = true;
            return;
          }
          clearInterval(this.interval);
          this.codeButton = "获取验证码";
          this.captcha = "";
          this.buttonDisable = false;
          this.managerSet1 = false;
          this.managerSet2 = false;
          this.schoolData.account = this.phoneNumber;
          this.schoolData.schoolManagerPhone = this.phoneNumber;
          this.schoolData.password = this.password;
          this.$http
            .put(`${this.$baseUrl}/school/info/update`, this.schoolData)
            .then((response) => {
              if (response.data.code != 200) {
                this.errorMessage = response.data.message;
                this.showErrorAlert = true;
                return;
              }
              this.$http
                .get(
                  `${this.$baseUrl}/school/info/findById/${this.schoolData.schoolId}`
                )
                .then((response) => {
                  if (response.data.code != 200) {
                    this.errorMessage = response.data.message;
                    this.showErrorAlert = true;
                    return;
                  }
                  localStorage.setItem(
                    "schoolData",
                    JSON.stringify(response.data.result)
                  );
                  this.schoolData = response.data.result;
                });
            });
        })
        .catch(() => {
          this.errorMessage = "请输入正确的验证码";
          this.showErrorAlert = true;
        });
    },
    goNext() {
      this.$http
        .get(
          `${this.$baseUrl}/sms/check?phone=${this.schoolData.schoolManagerPhone}&code=${this.captcha}`
        )
        .then((response) => {
          if (
            response.data.code != 200 ||
            response.data.result != "验证码校验成功"
          ) {
            this.errorMessage = "请输入正确的验证码";
            this.showErrorAlert = true;
            return;
          }
          clearInterval(this.interval);
          this.codeButton = "获取验证码";
          this.captcha = "";
          this.buttonDisable = false;
          this.managerSet1 = false;
          this.managerSet2 = true;
        })
        .catch(() => {
          this.errorMessage = "请输入正确的验证码";
          this.showErrorAlert = true;
        });
    },
    setDateSpring(val) {
      console.log(val);
      this.springTerm.termBeginDate = val[0];
      this.springTerm.termEndDate = val[1];
    },
    setMorningSpring(val) {
      this.springTerm.classBeginTime = val[0];
      this.springTerm.classEndTime = val[1];
    },
    setMiddleSpring(val) {
      this.springTerm.middleBeginTime = val[0];
      this.springTerm.middleEndTime = val[1];
    },
    setNightSpring(val) {
      this.springTerm.nightBeginTime = val[0];
      this.springTerm.nightEndTime = val[1];
    },
    setRepeatSpring(val) {
      let temp = [];
      for (let i of val) {
        temp.push(this.dates.indexOf(i) + 1);
      }
      this.springTerm.dayOfWeek = temp.sort().toString();
    },
    setDateFall(val) {
      this.fallTerm.termBeginDate = val[0];
      this.fallTerm.termEndDate = val[1];
    },
    setMorningFall(val) {
      this.fallTerm.classBeginTime = val[0];
      this.fallTerm.classEndTime = val[1];
    },
    setMiddleFall(val) {
      this.fallTerm.middleBeginTime = val[0];
      this.fallTerm.middleEndTime = val[1];
    },
    setNightFall(val) {
      this.fallTerm.nightBeginTime = val[0];
      this.fallTerm.nightEndTime = val[1];
    },
    setRepeatFall(val) {
      let temp = [];
      for (let i of val) {
        temp.push(this.dates.indexOf(i) + 1);
      }
      this.fallTerm.dayOfWeek = temp.sort().toString();
    },
    saveTerm1() {
      this.$http
        .put(`${this.$baseUrl}/school/time/config/update`, this.springTerm)
        .then((response) => {
          if (response.data.code != 200) {
            this.errorMessage = response.data.message;
            this.showErrorAlert = true;
            return;
          }
        });
      this.timeMorning1 = false;
    },
    saveTerm2() {
      this.$http
        .put(`${this.$baseUrl}/school/time/config/update`, this.fallTerm)
        .then((response) => {
          if (response.data.code != 200) {
            this.errorMessage = response.data.message;
            this.showErrorAlert = true;
            return;
          }
        });
      this.timeMorning2 = false;
    },
    getCode() {
      console.log(this.phoneNumber);
      // if (this.phoneNumber.length != 11) {
      //   this.errorMessage = "请输入正确的手机号";
      //   this.showErrorAlert = true;
      //   return;
      // }
      this.$http.get(
        `${this.$baseUrl}/sms/send?phone=${this.schoolData.schoolManagerPhone}`
      );
      this.buttonDisable = true;
      let remain = 60;
      this.codeButton = `${remain}秒`;
      this.interval = setInterval(() => {
        remain = remain - 1;
        this.codeButton = `${remain}秒`;
        if (remain == 0) {
          clearInterval(this.interval);
          this.buttonDisable = false;
          this.codeButton = "获取验证码";
        }
      }, 1000);
    },
    getCode2() {
      console.log(this.phoneNumber);
      if (this.phoneNumber.length != 11) {
        this.errorMessage = "请输入正确的手机号";
        this.showErrorAlert = true;
        return;
      }
      this.$http.get(`${this.$baseUrl}/sms/send?phone=${this.phoneNumber}`);
      this.buttonDisable = true;
      let remain = 60;
      this.codeButton = `${remain}秒`;
      let interval = setInterval(() => {
        remain = remain - 1;
        this.codeButton = `${remain}秒`;
        if (remain == 0) {
          clearInterval(interval);
          this.buttonDisable = false;
          this.codeButton = "获取验证码";
        }
      }, 1000);
    },
    weekParser(item) {
      if (item != null && item != undefined && item.dayOfWeek) {
        let list = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
        let res = [];
        for (let i of item.dayOfWeek.split(",")) {
          res.push(list[i - 1]);
        }
        return res.join("、");
      }
      return `暂未设置`;
    },
    dateParser(item) {
      if (item != null && item != undefined && item.termBeginDate != undefined) {
        console.log(item.termBeginDate);
        item.termBeginDate = item.termBeginDate.replaceAll('-','/');
        item.termEndDate = item.termEndDate.replaceAll('-','/');
        let date = new Date(item.termBeginDate);
        let date1 = new Date(item.termEndDate);
        return `${date.getFullYear()}年${
          date.getMonth() + 1
        }月${date.getDate()}日 - ${date1.getFullYear()}年${
          date1.getMonth() + 1
        }月${date1.getDate()}日`;
      }
      return `暂未设置`;
    },
    timeParser(item, type) {
      if (item != null && item != undefined) {
        if (type == 1 && item.classBeginTime) {
          return `${item.classBeginTime}-${item.classEndTime}`;
        } else if (type == 2 && item.middleBeginTime) {
          return `${item.middleBeginTime}-${item.middleEndTime}`;
        } else if (type == 3 && item.nightBeginTime) {
          return `${item.nightBeginTime}-${item.nightEndTime}`;
        }
      }
      return `暂未设置`;
    },
    selected(data) {
      console.log(data, "============================");
      this.areaCode = data.area.code;
      this.provinceCode = data.province.code;
      this.cityCode = data.city.code;
      this.prev = `${data.province.value},${data.city.value},${data.area.value}`;
      this.schoolAddress = `${data.province.value},${data.city.value},${data.area.value}`;
      this.complete = true;
    },
    setPlace() {
      if (this.complete == false) {
        this.placeSet = false;
        this.address = "";
        return;
      }
      this.schoolData.area = this.areaCode;
      this.schoolData.city = this.cityCode;
      this.schoolData.province = this.provinceCode;
      this.schoolAddress = this.prev + ` ${this.address}`;
      this.schoolData.schoolAddress = this.schoolAddress;
      this.$http
        .put(`${this.$baseUrl}/school/info/update`, this.schoolData)
        .then((response) => {
          if (response.data.code != 200) {
            this.errorMessage = response.data.message;
            this.showErrorAlert = true;
            return;
          }
          this.$http
            .get(
              `${this.$baseUrl}/school/info/findById/${this.schoolData.schoolId}`
            )
            .then((response) => {
              if (response.data.code != 200) {
                this.errorMessage = response.data.message;
                this.showErrorAlert = true;
                return;
              }
              localStorage.setItem(
                "schoolData",
                JSON.stringify(response.data.result)
              );
              this.schoolData = response.data.result;
              this.complete = true;
            });
        });
      this.placeSet = false;
      this.address = "";
    },
  },
};
</script>

<style scoped>
.confirm {
  position: absolute;
  width: 116px;
  right: 20px;
  top: 0;
}
.inner >>> select {
  max-width: 130px;
}
.item-white {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: white;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  white-space: pre;
  line-height: 22px;
  background: #f5f6fa;
}
.item-white:hover {
  background: #d2e3ff;
}

.right_items {
  margin-bottom: 16px;
  width: 414px;
  position: relative;
}
.inner {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  text-align: left;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
}
.card {
  margin: 10px;
  background-color: white;
  width: 550px;
  height: 275px;
  border-radius: 9px;
}

.detailRight {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  display: flex;
  align-items: center;
}

.item:hover {
  background-color: #85b4ff;
}

.smallTitle {
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.mainContainer {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
}

.rates {
  margin-left: 20px;
  display: flex;
  position: absolute;
  width: 40%;
  float: left;
  margin-top: 10px;
  height: calc(100% - 70px);
}
#main {
  width: 100%;
  float: right;
  height: calc(100% - 60px);
}

#mainLeft1 {
  display: grid;
  width: 50%;
  height: 100%;
}
#mainLeft2 {
  display: grid;
  width: 50%;
  height: 100%;
}

#main1 {
  width: 100%;
  height: 100%;
}

#main2 {
  width: 100%;
  height: 80%;
}

.selector {
  width: 95%;
  margin-top: 10px;
}

.smallSelector {
  width: 60px;
}

.smallSelector /deep/ .el-input__inner {
  height: 20px;
}
/* 下面设置右侧按钮居中 */
.smallSelector /deep/ .el-input__suffix {
  top: 1px;
}
.smallSelector /deep/ .el-input__icon {
  line-height: inherit;
}
.smallSelector /deep/ .el-input__suffix-inner {
  display: inline-block;
}
.user {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  position: absolute;
  right: 40px;
}

.content {
  margin-left: 180px;
  width: calc(100% - 180px);
  height: calc(100% - 170px);
  position: relative;
  top: 170px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
}

.top {
  background-color: white;
  position: absolute;
  top: 0;
  left: 160px;
  padding-left: 30px;
  padding-right: 30px;
  width: calc(100% - 220px);
  height: 120px;
  text-align: left;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 33px;
  display: flex;
  align-items: center;
}

.info {
  background-color: white;
  position: absolute;
  top: 120px;
  left: 160px;
  padding-left: 30px;
  padding-right: 30px;
  width: calc(100% - 220px);
  height: 60px;
  text-align: left;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  display: flex;
  align-items: center;
  background: #f5f6fa;
}

.details {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #666666;
}

.left {
  float: left;
  height: calc(100% - 50px);
  width: calc(100% - 590px);
  margin-left: 30px;
}
.right {
  float: right;
  position: absolute;
  right: 0;
  margin-right: 40px;
  height: calc(100% - 50px);
  /* display: flex;
  flex-direction: column; */
}

.topRight {
  flex: auto;
  height: 42%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
  margin-bottom: 20px;
}

.midRight {
  flex: auto;
  height: 22%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
}

.bottomRight {
  flex: auto;
  height: 31%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
}

.container_index {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 1900px;
  min-height: 920px;
  background: #f5f6fa;
}

.map {
  box-shadow: 0px 2px 4px 0px #b9d5fa;
}

.head {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: white;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  white-space: pre;
  line-height: 22px;
}

.menu {
  position: absolute;
  width: 160px;
  height: 100%;
  background-color: #5d9cff;
}
</style>