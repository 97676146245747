<template>
  <div class="container_index">
    <div class="top">
      <div>{{ schoolData.schoolName }}</div>
      <!-- <div style="margin-left: 190px">
        <span style="font-size: 16px">全校人数:</span> {{schoolStudents}}<span
          style="font-size: 16px"
          >人</span
        >
      </div>
      <div style="margin-left: 75px">
        <span style="font-size: 16px">学生卡数:</span> {{schoolDevices}}<span
          style="font-size: 16px"
          >人</span
        >
      </div> -->
      <!-- <div style="margin-left: 75px">
        <span style="font-size: 16px">在线率:</span
        ><span style="color: #5d9cff"> 99</span
        ><span style="font-size: 16px">%</span>
      </div> -->
      <div class="user">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <span style="font-size: 16px">{{ schoolData.schoolManager }}</span>
            <img src="../assets/ico_down.png" style="margin-left: 15px" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="info">
      <div
        style="
          width: 5px;
          height: 40px;
          background: #a2c8fa;
          margin-right: 20px;
        "
      ></div>
      <!-- <img src="../assets/ico_xiaoxi.png" style="margin-right: 10px" />
      <div>消息：</div>
      <img
        src="../assets/but_sos.png"
        style="position: absolute; right: 40px"
      /> -->
      <!-- <div class="detailRight" style="position: absolute; right: 40px">
        允许上级单位查看本校学生智慧卡相关信息
        <el-switch
          style="margin-left: 10px; margin-top: 2px"
          v-model="allow"
          active-color="#5D9CFF"
          inactive-color="#a5a5a5"
        >
        </el-switch>
      </div> -->
      <div class="details">
        消息中心<span
          style="margin-left: 20px; cursor: pointer; color: #5d9cff"
          @click="
            content1 = false;
            content2 = false;
          "
          v-if="content1 || content2"
          >返回</span
        >
      </div>
    </div>
    <div class="menu">
      <div class="head">
        <img :src="schoolData.schoolPhotoUrl==''?require('../assets/school.png') : schoolData.schoolPhotoUrl" style="margin-left: 23px;width:115px; height:115px" />
      </div>
      <div class="item" @click="$router.push('/index')">
        <img
          src="../assets/ico_shouye.png"
          style="margin-right: 4px"
        />&nbsp;首&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;页
      </div>
      <div class="item" @click="$router.push('/basicManage')">
        <div style="display: flex; align-items: center">
          <img
            style="margin-right: 10px"
            src="../assets/list_white.png"
          />基础管理
        </div>
      </div>
      <div class="item" @click="$router.push('/teacherApply')">
        <img
          style="margin-right: 10px"
          src="../assets/request_white.png"
        />教师申请
      </div>
      <div class="item" @click="$router.push('/studentApply')">
        <img
          style="margin-right: 10px"
          src="../assets/request_white.png"
        />学生申请
      </div>
      <div class="item" @click="$router.push('/deviceManage')">
        <img
          style="margin-right: 10px"
          src="../assets/devices_white.png"
        />设备管理
      </div>
      <div class="item" @click="$router.push('/signManage')">
        <img
          style="margin-right: 10px"
          src="../assets/signin_white.png"
        />签到管理
      </div>
      <div class="item" @click="$router.push('/leaveManage')">
        <img
          style="margin-right: 10px"
          src="../assets/leave_white.png"
        />请假管理
      </div>
      <div class="item" @click="$router.push('/noticeManage')">
        <img
          style="margin-right: 10px"
          src="../assets/speaker_white.png"
        />学校公告
      </div>
    </div>
    <div class="content" v-if="content1">
      <div class="card">
        <div class="inner">
          {{contentTitle}}
          <div style="font-size: 10px; margin-top: 10px; margin-bottom: 10px">
            {{contentTime}}发布
          </div>
          <div style="whitespace: pre-wrap">
            {{contentDetail}}
          </div>
        </div>
      </div>
    </div>
    <div class="content" v-if="!content1 && !content2">
      <div class="card">
        <div class="inner">
          <div
            style="
              font-size: 16px;
              margin-bottom: 10px;
              margin-top: 35px;
              position: relative;
            "
          >
            <span>标题：</span>
            <el-input
              v-model="input3"
              placeholder="请输入"
              style="width: 230px"
            ></el-input>
            <span style="margin-left: 80px">时间：</span>
            <el-select v-model="value1" placeholder="请选择">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button
              type="primary"
              icon="el-icon-search"
              style="position: absolute; right: 20px; width: 100px"
              >搜索</el-button
            >
          </div>
          <!-- <div
            style="
              font-size: 16px;
              position: relative;
              height: 25px;
              margin-bottom: 30px;
            "
          >
            <el-button
              type="warning"
              style="position: absolute; right: 130px; width: 100px"
              >导入</el-button
            >
            <el-button
              type="success"
              style="position: absolute; right: 240px; width: 100px"
              >导出</el-button
            >
            <el-button
              type="primary"
              style="position: absolute; right: 20px; width: 100px"
              >新增</el-button
            >
          </div> -->
          <div style="margin-top: 40px">
            <div class="tabs">
              <div
                v-bind:class="{
                  tabItem: !actived[0],
                  tabItemActive: actived[0],
                }"
                @click="changeColor(1)"
              >
                通知消息
              </div>
              <div
                v-bind:class="{
                  tabItem: !actived[1],
                  tabItemActive: actived[1],
                }"
                @click="changeColor(2)"
                style="border-left: 0px; border-right: 0px"
              >
                告警信息
              </div>
              <div
                v-bind:class="{
                  tabItem: !actived[2],
                  tabItemActive: actived[2],
                }"
                @click="changeColor(3)"
              >
                SOS消息
              </div>
            </div>
            <el-table
              :data="tableData"
              stripe
              style="width: 100%"
              :header-cell-style="{ background: '#E0EDFF', color: '#606266' }"
              max-height="420px"
              v-if="actived[0]"
            >
              <el-table-column type="index" label="序号" width="90" align="center">
            </el-table-column>
              <el-table-column
                prop="title"
                label="消息标题"
                width="180"
                align="center"
              >
              </el-table-column>
              <!-- <el-table-column
                prop="studentName"
                label="学生姓名"
                width="180"
                align="center"
              >
              </el-table-column> -->
              <el-table-column
                prop="content"
                label="消息内容"
                width="180"
                align="center"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <el-table-column
                prop="time"
                label="时间"
                width="180"
                align="center"
              >
              </el-table-column>

              <el-table-column prop="method" label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="showDetail(scope.$index, tableData)"
                    type="text"
                    size="small"
                  >
                    查看详情
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table
              :data="otherData"
              stripe
              style="width: 100%"
              :header-cell-style="{ background: '#E0EDFF', color: '#606266' }"
              max-height="420px"
              v-if="actived[1]"
            >
              <el-table-column
                type="index"
                label="序号"
                width="90"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="alarmType"
                label="消息标题"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="studentName"
                label="学生姓名"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="alarmMsg"
                label="消息内容"
                min-width="180"
                align="center"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="时间"
                width="180"
                align="center"
              >
              </el-table-column>

              <el-table-column prop="method" label="操作" align="center" width="180">
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="showDetail(scope.$index, otherData)"
                    type="text"
                    size="small"
                  >
                    查看详情
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-table
              :data="sosData"
              stripe
              style="width: 100%"
              :header-cell-style="{ background: '#E0EDFF', color: '#606266' }"
              max-height="420px"
              v-if="actived[2]"
            >
              <el-table-column
                type="index"
                label="序号"
                width="90"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="alarmType"
                label="消息标题"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="studentName"
                label="学生姓名"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="alarmMsg"
                label="消息内容"
                min-width="180"
                align="center"
                :show-overflow-tooltip="true"
              >
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="时间"
                width="180"
                align="center"
              >
              </el-table-column>

              <el-table-column prop="method" label="操作" align="center" width="180">
                <template slot-scope="scope">
                  <el-button
                    @click.native.prevent="showDetail(scope.$index, sosData)"
                    type="text"
                    size="small"
                  >
                    查看详情
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "noticeCenter",
  components: {},
  data() {
    return {
      schoolDevices: localStorage.getItem("schoolDevices"),
      schoolStudents: localStorage.getItem("schoolStudents"),
      mapType: "class",
      allow: false,
      input1: "",
      input2: "",
      input3: "",
      contentTitle: "",
      contentTime: '',
      contentDetail: '',
      isData: true,
      actived: [true, false, false],
      tableData: [
      ],
      options: [
        {
          value: "选项1",
          label: "一年级",
        },
        {
          value: "选项2",
          label: "二年级",
        },
        {
          value: "选项3",
          label: "三年级",
        },
        {
          value: "选项4",
          label: "四年级",
        },
        {
          value: "选项5",
          label: "五年级",
        },
      ],
      options1: [
        {
          value: "选项1",
          label: "病假",
        },
        {
          value: "选项2",
          label: "事假",
        },
      ],
      options2: [
        {
          value: "选项1",
          label: "一天",
        },
        {
          value: "选项2",
          label: "一周",
        },
      ],
      options3: [
        {
          value: "选项1",
          label: "一年级",
        },
        {
          value: "选项2",
          label: "二年级",
        },
        {
          value: "选项3",
          label: "三年级",
        },
      ],
      options4: [
        {
          value: "选项1",
          label: "一班",
        },
        {
          value: "选项2",
          label: "二班",
        },
        {
          value: "选项3",
          label: "三班",
        },
      ],
      value: "",
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      title: "",
      time: "",
      content1: false,
      content2: false,
      schoolData: JSON.parse(localStorage.getItem("schoolData")),
      sosData: [],
      otherData: [],
    };
  },
  mounted() {
    this.$http
      .get(
        `${this.$baseUrl}/device/alarm/findByCondition?page=1&size=100&schoolId=${this.schoolData.schoolId}`
      )
      .then((response) => {
        console.log(response.data.result.records);
        for (let i of response.data.result.records) {
          if (i.alarmType == "sos告警") {
            this.sosData.push(i);
          } else {
            if (i.alarmType != "进入家庭围栏告警" && i.alarmType != "离开家庭围栏告警") {
              this.otherData.push(i);
            }
          }
        }
      });
    if (this.$route.params.id == "Sos") {
      this.changeColor(3);
    } else if (this.$route.params.id == "Alarm") {
      this.changeColor(2);
    }
  },
  methods: {
    changeColor(type) {
      switch (type) {
        case 1:
          this.actived = [true, false, false];
          this.tableData = [
          ];
          break;
        case 2:
          this.actived = [false, true, false];
          break;
        case 3:
          this.actived = [false, false, true];
          break;
      }
    },
    showDetail(index, data) {
      console.log(index, data);
      console.log(data);
      this.content1 = true;
      this.contentTitle = data[index].studentName;
      this.contentTime = data[index].createTime;
      this.contentDetail = data[index].alarmMsg;
    },
    drawChartLeft1() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("mainLeft1"));
      let myContainer = document.getElementById("mainLeft1");
      let elementResizeDetectorMaker = require("element-resize-detector"); //引入监听dom变化的组件
      let erd = elementResizeDetectorMaker();
      let that = this;
      const data = [
        { value: 300, name: "全校设备数" },
        { value: 100, name: "设备申请数" },
        { value: 90, name: "申请通过数" },
      ];
      let option = {
        color: ["#D95F7F", "#03B307", "#5D9CFF"],
        legend: {
          data: ["全校设备数", "设备申请数", "申请通过数"],
          orient: "vertical",
          x: "80%",
          y: "65%",
          textStyle: {
            //图例字体大小
            fontSize: 12,
            color: "#666666",
          },
          itemHeight: 10,
          itemWidth: 10,
          type: "scroll",
          formatter: function (name) {
            //通过name获取到数组对象中的单个对象
            let singleData = data.filter(function (item) {
              return item.name == name;
            });
            return name + " : " + singleData[0].value;
          },
        },
        xAxis: {
          type: "category",
          data: ["全校设备数", "设备申请数", "申请通过数"],
        },
        yAxis: [
          {
            type: "value",
            splitLine: {
              //网格线
              show: false,
            },
          },
        ],
        grid: {
          left: "3%",
          right: "30%",
          bottom: "3%",
          top: "15%",
          containLabel: true,
        },
        series: [
          {
            name: "全校设备数",
            barWidth: 20,
            stack: "total",
            data: [
              {
                value: 300,
                itemStyle: {
                  color: "#D95F7F",
                },
              },
              0,
              0,
            ],
            type: "bar",
          },
          {
            name: "设备申请数",
            barWidth: 20,
            stack: "total",
            data: [
              0,
              {
                name: "设备申请数",
                value: 100,
                itemStyle: {
                  color: "#03B307",
                },
              },
              0,
            ],
            type: "bar",
          },
          {
            name: "申请通过数",
            barWidth: 20,
            stack: "total",
            data: [
              0,
              0,
              {
                value: 90,
                itemStyle: {
                  color: "#5D9CFF",
                },
              },
            ],
            type: "bar",
          },
        ],
      };
      myChart.setOption(option);
      erd.listenTo(myContainer, function () {
        // //执行监听
        myContainer.style.height = "100%";
        myContainer.style.width = "100%";
        that.$nextTick(function () {
          setTimeout(function () {
            myChart.resize();
          });
        });
      });
    },
    drawChartLeft2() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("mainLeft2"));
      let myContainer = document.getElementById("mainLeft2");
      let elementResizeDetectorMaker = require("element-resize-detector"); //引入监听dom变化的组件
      let erd = elementResizeDetectorMaker();
      let that = this;
      const data = [
        { value: 90, name: "在线设备数" },
        { value: 0, name: "离线申请数" },
        { value: 10, name: "救援设备数" },
      ];
      let option = {
        color: ["#5D9CFF", "#BD0104", "#FFB964"],
        legend: {
          data: ["在线设备数", "离线申请数", "救援设备数"],
          orient: "vertical",
          x: "80%",
          y: "65%",
          textStyle: {
            //图例字体大小
            fontSize: 12,
            color: "#666666",
          },
          itemHeight: 10,
          itemWidth: 10,
          type: "scroll",
          formatter: function (name) {
            //通过name获取到数组对象中的单个对象
            let singleData = data.filter(function (item) {
              return item.name == name;
            });
            return name + " : " + singleData[0].value;
          },
        },
        xAxis: {
          type: "category",
          data: ["在线设备数", "离线申请数", "救援设备数"],
        },
        yAxis: [
          {
            type: "value",
            splitLine: {
              //网格线
              show: false,
            },
          },
        ],
        grid: {
          left: "3%",
          right: "30%",
          bottom: "3%",
          top: "15%",
          containLabel: true,
        },
        series: [
          {
            name: "在线设备数",
            barWidth: 20,
            stack: "total",
            data: [
              {
                value: 90,
                itemStyle: {
                  color: "#5D9CFF",
                },
              },
              0,
              0,
            ],
            type: "bar",
          },
          {
            name: "离线申请数",
            barWidth: 20,
            stack: "total",
            data: [
              0,
              {
                value: 0,
                itemStyle: {
                  color: "#BD0104",
                },
              },
              0,
            ],
            type: "bar",
          },
          {
            name: "救援设备数",
            barWidth: 20,
            stack: "total",
            data: [
              0,
              0,
              {
                value: 10,
                itemStyle: {
                  color: "#FFB964",
                },
              },
            ],
            type: "bar",
          },
        ],
      };
      myChart.setOption(option);
      erd.listenTo(myContainer, function () {
        // //执行监听
        myContainer.style.height = "100%";
        myContainer.style.width = "100%";
        that.$nextTick(function () {
          setTimeout(function () {
            myChart.resize();
          });
        });
      });
    },
    drawChartLeft3() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("mainLeft3"));
      let myContainer = document.getElementById("mainLeft3");
      let elementResizeDetectorMaker = require("element-resize-detector"); //引入监听dom变化的组件
      let erd = elementResizeDetectorMaker();
      let that = this;
      let option = {
        xAxis: {
          type: "category",
          data: [
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
          ],
        },
        yAxis: [
          {
            type: "value",
            splitLine: {
              //网格线
              show: false,
            },
          },
        ],
        grid: {
          left: "3%",
          right: "3%",
          bottom: "3%",
          top: "15%",
          containLabel: true,
        },
        series: [
          {
            data: [260, 150, 230, 224, 218, 135, 147, 230, 224, 218, 135, 147],
            type: "line",
          },
        ],
      };
      myChart.setOption(option);
      erd.listenTo(myContainer, function () {
        // //执行监听
        myContainer.style.height = "100%";
        myContainer.style.width = "100%";
        that.$nextTick(function () {
          setTimeout(function () {
            myChart.resize();
          });
        });
      });
    },
  },
};
</script>

<style scoped>
.tabs {
  display: flex;
  align-items: center;
}

.tabItem {
  border: 1px solid #dadada;
  border-bottom: 0px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.tabItemActive {
  border: 1px solid #dadada;
  border-bottom: 0px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  background: #a2c8fa;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.item-white {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: white;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  white-space: pre;
  line-height: 22px;
  background: #f5f6fa;
}
.item-white:hover {
  background: #d2e3ff;
}

.right_items {
  margin-bottom: 16px;
  width: 414px;
  position: relative;
}
.inner {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
  text-align: left;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
}
.card {
  margin: 10px;
  background-color: white;
  width: 1700px;
  height: 600px;
  border-radius: 9px;
}

.detailRight {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  display: flex;
  align-items: center;
}

.item:hover {
  background-color: #85b4ff;
}

.smallTitle {
  font-size: 10px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.mainContainer {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
}

.rates {
  margin-left: 20px;
  display: flex;
  position: absolute;
  width: 40%;
  float: left;
  margin-top: 10px;
  height: calc(100% - 70px);
}
#main {
  width: 100%;
  float: right;
  height: calc(100% - 60px);
}

#mainLeft1 {
  display: grid;
  width: 50%;
  height: 100%;
}
#mainLeft2 {
  display: grid;
  width: 50%;
  height: 100%;
}

#main1 {
  width: 100%;
  height: 100%;
}

#main2 {
  width: 100%;
  height: 80%;
}

.selector {
  width: 95%;
  margin-top: 10px;
}

.smallSelector {
  width: 60px;
}

.smallSelector /deep/ .el-input__inner {
  height: 20px;
}
/* 下面设置右侧按钮居中 */
.smallSelector /deep/ .el-input__suffix {
  top: 1px;
}
.smallSelector /deep/ .el-input__icon {
  line-height: inherit;
}
.smallSelector /deep/ .el-input__suffix-inner {
  display: inline-block;
}
.user {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 22px;
  position: absolute;
  right: 40px;
}

.content {
  margin-left: 180px;
  width: calc(100% - 180px);
  height: calc(100% - 170px);
  position: relative;
  top: 170px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
}

.top {
  background-color: white;
  position: absolute;
  top: 0;
  left: 160px;
  padding-left: 30px;
  padding-right: 30px;
  width: calc(100% - 220px);
  height: 120px;
  text-align: left;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 33px;
  display: flex;
  align-items: center;
}

.info {
  background-color: white;
  position: absolute;
  top: 120px;
  left: 160px;
  padding-left: 30px;
  padding-right: 30px;
  width: calc(100% - 220px);
  height: 60px;
  text-align: left;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  display: flex;
  align-items: center;
  background: #f5f6fa;
}

.details {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #666666;
}

.left {
  float: left;
  height: calc(100% - 50px);
  width: calc(100% - 590px);
  margin-left: 30px;
}
.right {
  float: right;
  position: absolute;
  right: 0;
  margin-right: 40px;
  height: calc(100% - 50px);
  /* display: flex;
  flex-direction: column; */
}

.topRight {
  flex: auto;
  height: 42%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
  margin-bottom: 20px;
}

.midRight {
  flex: auto;
  height: 22%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
}

.bottomRight {
  flex: auto;
  height: 31%;
  width: 480px;
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(185, 213, 250, 0.29);
  border-radius: 4px;
}

.container_index {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 1900px;
  min-height: 920px;
  background: #f5f6fa;
}

.map {
  box-shadow: 0px 2px 4px 0px #b9d5fa;
}

.head {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: white;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  white-space: pre;
  line-height: 22px;
}

.menu {
  position: absolute;
  width: 160px;
  height: 100%;
  background-color: #5d9cff;
}
</style>